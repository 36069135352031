import React, { useEffect, useLayoutEffect } from 'react';
import { LoadingService } from '../../services/loading.service';
import { SnackService } from '../../services/snack.service';
import './painel-vendas.page.scss';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { ListaVendasService } from '../../services/api-usuario.service';
import UsuarioModel from '../../models/usuario.model';
import VendaModel from '../../models/venda.model';
import ProdutoFormModel from '../../models/produto-form.model';
import { UsuarioLogadoService } from '../../services/usuario-efetuou-login.service';
import SelectAuditoriaComponent from '../../components/select-auditoria/select-auditoria.component';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useInput } from '../../hooks/useInput.hook';

let myExcelXML = (function () {
  let Workbook, WorkbookStart = '<?xml version="1.0"?><ss:Workbook  xmlns="urn:schemas-microsoft-com:office:spreadsheet" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet" xmlns:html="http://www.w3.org/TR/REC-html40">';
  const WorkbookEnd = '</ss:Workbook>';
  let fs, SheetName = 'SHEET 1',
    styleID = 1, columnWidth = 80,
    fileName = "export_vendas", uri, link;

  class myExcelXML {
    constructor(o) {
      let respArray = JSON.parse(o);
      let finalDataArray: any = [];

      for (let i = 0; i < respArray.length; i++) {
        finalDataArray.push(flatten(respArray[i]));
      }

      let s = JSON.stringify(finalDataArray);
      fs = s.replace(/&/gi, '&amp;');
    }

    downLoad() {
      const Worksheet = myXMLWorkSheet(SheetName, fs);

      WorkbookStart += myXMLStyles(styleID);

      Workbook = WorkbookStart + Worksheet + WorkbookEnd;

      uri = 'data:text/xls;charset=utf-8,' + encodeURIComponent(Workbook);
      link = document.createElement("a");
      link.href = uri;
      link.style = "visibility:hidden";
      link.download = fileName + ".xls";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    get fileName() {
      return fileName;
    }

    set fileName(n) {
      fileName = n;
    }

    get SheetName() {
      return SheetName;
    }

    set SheetName(n) {
      SheetName = n;
    }

    get styleID() {
      return styleID;
    }

    set styleID(n) {
      styleID = n;
    }
  }

  const myXMLStyles = function (id) {
    let Styles = '<ss:Styles><ss:Style ss:ID="' + id + '"><ss:Font ss:Bold="1"/></ss:Style></ss:Styles>';

    return Styles;
  }

  const myXMLWorkSheet = function (name, o) {
    const Table = myXMLTable(o);
    let WorksheetStart = '<ss:Worksheet ss:Name="' + name + '">';
    const WorksheetEnd = '</ss:Worksheet>';

    return WorksheetStart + Table + WorksheetEnd;
  }

  const myXMLTable = function (o) {
    let TableStart = '<ss:Table>';
    const TableEnd = '</ss:Table>';

    const tableData = JSON.parse(o);

    if (tableData.length > 0) {
      const columnHeader = Object.keys(tableData[0]);
      let rowData;
      for (let i = 0; i < columnHeader.length; i++) {
        TableStart += myXMLColumn(columnWidth);

      }
      for (let j = 0; j < tableData.length; j++) {
        rowData += myXMLRow(tableData[j], columnHeader);
      }
      TableStart += myXMLHead(1, columnHeader);
      TableStart += rowData;
    }

    return TableStart + TableEnd;
  }

  const myXMLColumn = function (w) {
    return '<ss:Column ss:AutoFitWidth="0" ss:Width="' + w + '"/>';
  }


  const myXMLHead = function (id, h) {
    let HeadStart = '<ss:Row ss:StyleID="' + id + '">';
    const HeadEnd = '</ss:Row>';

    for (let i = 0; i < h.length; i++) {
      const Cell = myXMLCell(h[i].toUpperCase());
      HeadStart += Cell;
    }

    return HeadStart + HeadEnd;
  }

  const myXMLRow = function (r, h) {
    let RowStart = '<ss:Row>';
    const RowEnd = '</ss:Row>';
    for (let i = 0; i < h.length; i++) {
      const Cell = myXMLCell(r[h[i]]);
      RowStart += Cell;
    }

    return RowStart + RowEnd;
  }

  const myXMLCell = function (n) {
    let CellStart = '<ss:Cell>';
    const CellEnd = '</ss:Cell>';

    const Data = myXMLData(n);
    CellStart += Data;

    return CellStart + CellEnd;
  }

  const myXMLData = function (d) {
    let DataStart = '<ss:Data ss:Type="String">';
    const DataEnd = '</ss:Data>';

    return DataStart + d + DataEnd;
  }

  const flatten = function (obj) {
    var obj1 = JSON.parse(JSON.stringify(obj));
    const obj2 = JSON.parse(JSON.stringify(obj));
    if (typeof obj === 'object') {
      for (var k1 in obj2) {
        if (obj2.hasOwnProperty(k1)) {
          if (typeof obj2[k1] === 'object' && obj2[k1] !== null) {
            delete obj1[k1]
            for (var k2 in obj2[k1]) {
              if (obj2[k1].hasOwnProperty(k2)) {
                obj1[k1 + '-' + k2] = obj2[k1][k2];
              }
            }
          }
        }
      }
      var hasObject = false;
      for (var key in obj1) {
        if (obj1.hasOwnProperty(key)) {
          if (typeof obj1[key] === 'object' && obj1[key] !== null) {
            hasObject = true;
          }
        }
      }
      if (hasObject) {
        return flatten(obj1);
      } else {
        return obj1;
      }
    } else {
      return obj1;
    }
  }

  return myExcelXML;
})();

export default function PainelVendasPage() {

  const [vendas, setVendas] = React.useState<VendaModel[]>();
  const [vendasBK, setVendasBK] = React.useState<VendaModel[]>();
  const [totalVendas, setTotalVendas] = React.useState<string>();
  const [order, setOrder] = React.useState<any>({
    key: 'data',
    isASC: true
  });
  const [totalQTD, setTotalQTD] = React.useState<number>();
  const [totalDesconto, setTotalDesconto] = React.useState<string>();
  const [usuarioLogado, setUsuarioLogado] = React.useState<UsuarioModel | undefined>(UsuarioLogadoService.getUsuario());
  const { value: cpfInputText, bind: bindCPFInputText, setValue: setCPFInputText } = useInput('');
  const { value: cpfCliInputText, bind: bindCPFcliInputText, setValue: setCPFcliInputText } = useInput('');
  const { value: dataInputText, bind: bindDataInputText, setValue: setDataInputText } = useInput('');
  let history = useHistory();
  const [auditoria, setAuditoria] = React.useState<string>();

  useEffect(() => {
    ListaVendas();
  }, []);

  const ListaVendas = () => {
    LoadingService.sendLoadingAtivo(true);

    if (!usuarioLogado?.ID) {
      LoadingService.sendLoadingAtivo(false);
      return;
    }

    var dto = {
      user_id: usuarioLogado?.ID,
    };

    ListaVendasService(dto).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setVendas(res.data);
        setVendasBK(res.data);
        CalcTotalVendas(res.data);
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão ao carregar página.');
      console.log(err);
    });
  }

  const CalcTotalVendas = (vendas: VendaModel[]) => {
    let _totVendas = 0;
    let _totQTD = 0;
    let _totDesconto = 0;

    vendas.map(itemVenda => {
      _totVendas += parseInt(itemVenda.valor_da_venda?.replace("R$ ", "").replace(/\D/g, "") + '');
      itemVenda.produtos_vendidos?.map(itemProduto => {
        _totQTD += parseInt(itemProduto.quantidade + '');
      });
      _totDesconto += parseInt(itemVenda.total_desconto?.replace("R$ ", "").replace(/\D/g, "") + '');
    });

    setTotalVendas(BRLMask(_totVendas + ''));
    setTotalQTD(_totQTD);
    setTotalDesconto(BRLMask(_totDesconto + ''));
  }

  const BRLMask = (valor: any) => {
    if (valor) {
      let valorAlterado = valor;
      valorAlterado = valorAlterado.replace("R$ ", "");
      valorAlterado = valorAlterado.replace(/\D/g, ""); // Remove todos os não dígitos
      valorAlterado = valorAlterado.replace(/(\d+)(\d{2})$/, "$1,$2"); // Adiciona a parte de centavos
      valorAlterado = valorAlterado.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."); // Adiciona pontos a cada três dígitos
      valorAlterado = "R$ " + valorAlterado;
      return valorAlterado;
    }
  }

  const cpfCnpjMask = (v: string) => {

    console.log(v.length);
    v = v.replace(/\D/g, "");
    if (v.length <= 13) {
      //v = v.substring(0, 11);
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    } else {
      v = v.replace(/\D+/g, '');
      v = v.replaceAll('.', '');
      v = v.replaceAll('-', '');
      v = v.replace(/(\d{2})(\d)/, '$1.$2');
      v = v.replace(/(\d{3})(\d)/, '$1.$2');
      v = v.replace(/(\d{3})(\d)/, '$1/$2');
      v = v.replace(/(\d{4})(\d)/, '$1-$2');
      v = v.replace(/(-\d{2})\d+?$/, '$1');
    }

    return v;
  }

  const VerEditar = (venda: VendaModel) => {
    history?.push({
      pathname: `/cadastro-venda`,
      state: {
        vendaPainvel: venda
      }
    })
  }

  const ContaProdutos = (produtos?: ProdutoFormModel[]) => {
    let totProds = 0;

    produtos?.map(item => {
      totProds += parseInt(item.quantidade + '');
    });

    return totProds;
  }

  const OrdenarVendas = (key_tabela: string) => {
    //vendas?.sort(compareDataASC);

    let newArr = vendas?.sort((a: any, b: any, key = key_tabela) => {
      // a[key] = a[key].replaceAll('R$ ', '').replaceAll('.', '').replaceAll(',', '').replaceAll('-', '');
      // b[key] = b[key].replaceAll('R$ ', '').replaceAll('.', '').replaceAll(',', '').replaceAll('-', '');
      //a[key] = parseInt(a[key]);
      // console.log(a, '---aaa');

      if (a[key] < b[key]) {
        if (order.isASC) {
          return -1;
        } else {
          return 1;
        }
      }
      if (a[key] > b[key]) {
        if (order.isASC) {
          return 1;
        } else {
          return -1;
        }
      }
      return 0;
    });
    setVendas([]);

    setOrder({
      key: key_tabela,
      isASC: !order.isASC
    });

    setTimeout(() => {
      setVendas(newArr);
    }, 100);
  }

  const cpfMask = (v: string) => {
    v = v.replace(/\D/g, "");
    if (v.length <= 13) {
      //v = v.substring(0, 11);
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    } else {
      v = v.replace(/\D+/g, '');
      v = v.replaceAll('.', '');
      v = v.replaceAll('-', '');
      v = v.replace(/(\d{2})(\d)/, '$1.$2');
      v = v.replace(/(\d{3})(\d)/, '$1.$2');
      v = v.replace(/(\d{3})(\d)/, '$1/$2');
      v = v.replace(/(\d{4})(\d)/, '$1-$2');
      v = v.replace(/(-\d{2})\d+?$/, '$1');
    }

    return v;
  }

  const limpaCPFCNPJ = (v: string | undefined) => {
    if (v) {
      v = v.replace(/\D/g, "");
    }
    return v;
  }

  const Filtrar = () => {
    //vendas?.sort(compareDataASC);
    var newArr: VendaModel[];

    if (vendasBK) {
      if (cpfInputText) {
        newArr = vendasBK.filter(x => limpaCPFCNPJ(x.cnpj_loja) === limpaCPFCNPJ(cpfInputText));
      } else {
        newArr = vendasBK;
      }

      if (cpfCliInputText) {
        newArr = newArr.filter(x => limpaCPFCNPJ(x.cpf_cnpj) == limpaCPFCNPJ(cpfCliInputText));
      }

      if (dataInputText) {
        newArr = newArr.filter(x => x.data == dataInputText.replaceAll("\t", ""));
      }

      if (auditoria) {
        console.log(auditoria, 'auditoria');
        if (auditoria == 'PENDENTE') {
          console.log(newArr, '--newArr');
          newArr = newArr.filter(x => x.auditoria == auditoria || x.auditoria == null);
        } else {
          newArr = newArr.filter(x => x.auditoria == auditoria);
        }
      }
    }

    setVendas([]);

    setTimeout(() => {
      setVendas(newArr);
      CalcTotalVendas(newArr);
    }, 100);
  }

  const LimparFiltrar = () => {
    let newArr: VendaModel[];
    setCPFInputText('');
    setCPFcliInputText('');
    setDataInputText('');
    setAuditoria('');

    if (vendasBK) {
      newArr = vendasBK;
    }

    setVendas([]);

    setTimeout(() => {
      setVendas(newArr);
      CalcTotalVendas(newArr);
    }, 100);
  }

  const handleChange = (event: SelectChangeEvent) => {
    setAuditoria(event.target.value as string);
  };

  const ExportarExcel = () => {
    if (vendas) {
      let newArr: any = [];
      vendas.map(item => {
        newArr.push({
          data: item.data,
          cnpj_loja: item.cnpj_loja,
          nome_loja: item.nome_loja,
          cpf_cnpj: item.cpf_cnpj,
          pneus: ContaProdutos(item.produtos_vendidos),
          valor_desconto: item.total_desconto,
          valor_nf: item.valor_da_venda,
          nf: item.nf,
          auditoria: item.auditoria == null ? 'PENDENTE' : item.auditoria
        });
      });
      var myTestXML = new myExcelXML(JSON.stringify(newArr));
      myTestXML.downLoad();
    }
  }

  return (
    <>
      <section className="painel-vendas">
        <div className="container-fluid">
          <div className="filtro flex flex-sp flex-cv flex-wr">
            <h1>Painel de vendas</h1>
            {
              usuarioLogado &&
              usuarioLogado.auditoria == 'Sim' &&
              <div className="campos flex">
                <TextField {...bindCPFInputText} label="Loja (CNPJ)" />
                <TextField {...bindCPFcliInputText} label="Cliente (CPF/CNPJ)" />
                <TextField {...bindDataInputText} label="Data (dd/mm/aaaa)" />
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Auditoria</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={auditoria}
                      label="Auditoria"
                      onChange={handleChange}
                    >
                      <MenuItem value="">Selecione</MenuItem>
                      <MenuItem value="PENDENTE">PENDENTE</MenuItem>
                      <MenuItem value="APROVADO">APROVADO</MenuItem>
                      <MenuItem value="REPROVADO">REPROVADO</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Button className='bt' onClick={() => Filtrar()}>Filtrar</Button>
                <Button className='bt red' onClick={() => LimparFiltrar()}>Limpar</Button>
              </div>
            }
          </div>
          {
            (usuarioLogado && vendas) &&
            <div className="table-responsive">
              <table className='painel'>
                <thead>
                  <tr>
                    <th className={'data' + order.key + ' ' + order.isASC} onClick={() => OrdenarVendas('data')}>
                      Data <ArrowDownwardIcon />
                    </th>
                    <th className={'cnpj_loja' + order.key + ' ' + order.isASC} onClick={() => OrdenarVendas('cnpj_loja')}>
                      CNPJ loja <ArrowDownwardIcon />
                    </th>
                    <th className={'nome_loja' + order.key + ' ' + order.isASC} onClick={() => OrdenarVendas('nome_loja')}>
                      Nome loja <ArrowDownwardIcon />
                    </th>
                    <th className={'cpf_cnpj' + order.key + ' ' + order.isASC} onClick={() => OrdenarVendas('cpf_cnpj')}>
                      Cliente <ArrowDownwardIcon />
                    </th>
                    <th className={'produtos_vendidos' + order.key + ' ' + order.isASC} onClick={() => OrdenarVendas('produtos_vendidos')}>
                      Pneus <ArrowDownwardIcon />
                    </th>
                    <th className={'total_desconto' + order.key + ' ' + order.isASC} onClick={() => OrdenarVendas('total_desconto')}>
                      Valor desconto <ArrowDownwardIcon />
                    </th>
                    <th className={'valor_da_venda' + order.key + ' ' + order.isASC} onClick={() => OrdenarVendas('valor_da_venda')}>
                      Valor NF <ArrowDownwardIcon />
                    </th>
                    {
                      usuarioLogado.auditoria == 'Sim' ?
                        <>
                          <th>Ver NF</th>
                          <th>Auditoria</th>
                        </>
                        :
                        <th></th>
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    vendas && vendas.map((itemVenda, indexVenda) => (
                      <tr key={indexVenda}>
                        <td>{itemVenda.data}</td>
                        <td>{itemVenda.cnpj_loja && cpfCnpjMask(itemVenda.cnpj_loja)}</td>
                        <td>{itemVenda.nome_loja}</td>
                        <td>{itemVenda.cpf_cnpj && cpfCnpjMask(itemVenda.cpf_cnpj)}</td>
                        <td>{ContaProdutos(itemVenda.produtos_vendidos)}</td>
                        <td>{itemVenda.total_desconto}</td>
                        <td>{itemVenda.valor_da_venda}</td>
                        {
                          usuarioLogado.auditoria == 'Sim' ?
                            <>
                              <td>
                                <Button href={itemVenda.nf} target='_blank'>Ver NF</Button>
                              </td>
                              <td>
                                <SelectAuditoriaComponent valSelect={itemVenda.auditoria} post_id={itemVenda.post_id} />
                              </td>
                            </>
                            :
                            <td>
                              <Button className='bt' onClick={() => VerEditar(itemVenda)}>Ver/Editar</Button>
                            </td>
                        }
                      </tr>
                    ))
                  }
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={4}>Total vendas: {vendas.length}</td>
                    <td>{totalQTD}</td>
                    <td>{totalDesconto}</td>
                    <td>{totalVendas}</td>
                    {
                      usuarioLogado.auditoria == 'Sim' ?
                        <>
                          <td></td>
                          <td></td>
                        </>
                        :
                        <td></td>
                    }
                  </tr>
                </tfoot>
              </table>
            </div>
          }
          {
            usuarioLogado &&
            usuarioLogado.auditoria == 'Sim' &&
            <div className="flex flex-ri">
              <Button className='bt export' onClick={() => ExportarExcel()}>Exportar Excel</Button>
            </div>
          }
        </div>
      </section>
    </>
  );
}