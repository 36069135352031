import React from 'react';
import './campos-produtos.component.scss';
import { Autocomplete, Button, TextField } from '@mui/material';
import ProdutoFormModel from '../../models/produto-form.model';
import { useInput } from '../../hooks/useInput.hook';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ProdutoModel from '../../models/produto.model';

export type CamposProdutosProps = {
  sendProduto: (produto: ProdutoFormModel, index: number) => void;
  sendDeleteProduto: (index: number) => void;
  produto: ProdutoFormModel;
  options: string[];
  index: number;
}

function CamposProdutosComponent(props: CamposProdutosProps) {

  const [produtos, setProdutos] = React.useState<string[]>(props.options);
  const [produtoInputText, setProdutoInputText] = React.useState<string>(props.produto.produto);
  const [produtoVal, setProdutoVal] = React.useState<string>(props.produto.produto);
  const [qtdInputText, setQtdInputText] = React.useState<number>(props.produto.quantidade);

  const InputProdutoChange = (val: string) => {
    setProdutoInputText(val);
    props.sendProduto({
      produto: val,
      quantidade: qtdInputText
    }, props.index);
  }

  const InputQTDChange = (val: any) => {
    let valor = parseInt(val.target.value) < 1 ? 1 : val.target.value;

    setQtdInputText(valor);
    props.sendProduto({
      produto: produtoInputText,
      quantidade: valor
    }, props.index);
  }

  const deleteProduto = (index: number) => {
    props.sendDeleteProduto(index);
  }

  return (
    <div className='campos-produtos flex'>
      <Autocomplete
        disablePortal
        options={produtos}
        inputValue={produtoInputText}
        renderInput={(param) => <TextField {...param} label={"Produto"} />}
        value={produtoVal}
        onInputChange={(event, newInputValue) => {
          InputProdutoChange(newInputValue);
          setProdutoVal(newInputValue);
        }}
      />
      <TextField type='number' value={qtdInputText} onChange={(e: any) => InputQTDChange(e)} label="QTD" />
      <Button className='bt' onClick={() => deleteProduto(props.index)}><DeleteOutlineIcon /></Button>
    </div>
  );
}

export default CamposProdutosComponent;
