import { Redirect, Route, RouteProps } from 'react-router';
import { isAuhenticated } from './services/auth';

export type ProtectedRouteProps = RouteProps;

export default function ProtectedRoute({...routeProps}: ProtectedRouteProps) {
  if(isAuhenticated()) {
    return <Route {...routeProps} />;
  } else {
    return <Redirect to={{ pathname: '/' }} />;
  }
};