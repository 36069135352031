import './busca-cliente.component.scss';
import { useInput } from '../../hooks/useInput.hook';
import { Button, TextField } from '@mui/material';
import { SnackService } from '../../services/snack.service';
import BuscaClienteModel from '../../models/busca-cliente.model';
import { LoadingService } from '../../services/loading.service';
import { BuscaClienteService } from '../../services/api-usuario.service';
import ClientesModel from '../../models/clientes.model';
import React, { useEffect } from 'react';
import { BuscaClienteOBSService } from '../../services/busca-cliente.service';
import UsuarioModel from '../../models/usuario.model';

export type BuscaComponentProps = {
  sendCliente: (cliente: ClientesModel) => void;
}

function BuscaComponent(props: BuscaComponentProps) {
  var dto: BuscaClienteModel;
  const { value: cpfInputText, bind: bindCPFInputText, setValue: setCPFInputText } = useInput('');
  const [usuarioLogado, setUsuarioLogado] = React.useState<UsuarioModel>();

  useEffect(() => {
    BuscaClienteOBSService.getBusca().subscribe((cpf_cnpj_cliente) => {
      setCPFInputText(cpf_cnpj_cliente);
    });

    let usLogado = localStorage.getItem("user");
    if (usLogado) {
      setUsuarioLogado(JSON.parse(usLogado));
    }
  }, []);

  useEffect(() => {
    if (validaCPF(cpfInputText)) {
      BuscarCliente(false)
    }
  }, [cpfInputText]);

  const validaCPF = (strCPF: string) => {
    strCPF = strCPF.replaceAll('.', '');
    strCPF = strCPF.replaceAll('/', '');
    strCPF = strCPF.replaceAll('-', '');

    console.log(strCPF.length);

    if (strCPF.length > 11) {
      console.log('estou no cnpj');
      console.log('strCPF > ', strCPF);
      return validaCNPJ(strCPF);
    } else {
      var Soma: any;
      var Resto: any;
      Soma = 0;
      if (strCPF == "00000000000") return false;
      let i: number = 0;
      for (i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;

      if ((Resto == 10) || (Resto == 11)) Resto = 0;
      if (Resto != parseInt(strCPF.substring(9, 10))) return false;

      Soma = 0;
      for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;

      if ((Resto == 10) || (Resto == 11)) Resto = 0;
      if (Resto != parseInt(strCPF.substring(10, 11))) return false;
      return true;
    }
  }

  const validaCNPJ = (cnpj: string) => {
    var b: any = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
    var c: any = String(cnpj).replace(/[^\d]/g, '')

    if (c.length !== 14)
      return false

    if (/0{14}/.test(c))
      return false

    for (var i = 0, n = 0; i < 12; n += c[i] * b[++i]);
    if (c[12] != (((n %= 11) < 2) ? 0 : 11 - n))
      return false

    for (var i = 0, n = 0; i <= 12; n += c[i] * b[i++]);
    if (c[13] != (((n %= 11) < 2) ? 0 : 11 - n))
      return false

    return true
  }

  const BuscarCliente = (isNoAlert: boolean) => {

    if (!cpfInputText && isNoAlert) {
      SnackService.sendMSG('O campo "CPF/CNPJ ou Cupom" é obrigatório');
      return;
    }


    dto = {
      cpf_cnpj_cupom: cpfMask(cpfInputText)
    };

    LoadingService.sendLoadingAtivo(true);

    BuscaClienteService(dto).then((res) => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data?.error) {
        SnackService.sendMSG(res.data?.error);
      } else {

        if (res?.data?.cliente_nao_encontrado) {
          SnackService.sendMSG(res?.data?.cliente_nao_encontrado);
        } else if (res?.data?.cpf_cnpj) {
          props.sendCliente(res.data);
          LimparForm();
        }
      }
    }).catch(err => {
      LoadingService.sendLoadingAtivo(false);
      SnackService.sendMSG('Erro com a conexão.');
      console.log(err);
    });
  }

  const LimparForm = () => {
    setCPFInputText('');
  }

  const cpfMask = (v: string) => {
    v = v.replace(/\D/g, "");
    if (v.length <= 13) {
      //v = v.substring(0, 11);
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    } else {
      v = v.replace(/\D+/g, '');
      v = v.replaceAll('.', '');
      v = v.replaceAll('-', '');
      v = v.replace(/(\d{2})(\d)/, '$1.$2');
      v = v.replace(/(\d{3})(\d)/, '$1.$2');
      v = v.replace(/(\d{3})(\d)/, '$1/$2');
      v = v.replace(/(\d{4})(\d)/, '$1-$2');
      v = v.replace(/(-\d{2})\d+?$/, '$1');
    }

    return v;
  }

  return (
    <>
      <div className="busca-cliente flex flex-cv">
        <TextField {...bindCPFInputText} value={cpfMask(cpfInputText)} label="CPF/CNPJ" />
        <Button className='bt' onClick={() => BuscarCliente(true)}>Buscar</Button>
      </div>
    </>
  );
}

export default BuscaComponent;
