
import EnvironmentModel from "./environment.model"

let env: EnvironmentModel = {
    env: "dev",
    serverUrl: "http://localhost/sistema-cupons/back",
    pagesUrl: "http://localhost/sistema-cupons/back",
};

const getEnv = (urlAtual: string) => {
    if ((urlAtual).indexOf('a7apps') >= 0) { // hml
        env = {
            env: "hml",
            serverUrl: "https://prometeon.a7apps.com/back",
            pagesUrl: "https://prometeon.a7apps.com/back",
        };
    } else if ((urlAtual).indexOf('prometeoncomvoce.com.br') >= 0) { // prod
        env = {
            env: "prd",
            serverUrl: "https://prometeoncomvoce.com.br/back",
            pagesUrl: "https://prometeoncomvoce.com.br/back",
        };
    } else if ((urlAtual).indexOf('localhost') >= 0) { // local
        env = {
            env: "local",
            serverUrl: "http://localhost/sistema-cupons/back",
            pagesUrl: "http://localhost/sistema-cupons/back",
        };
    }
    
    return env;
}

export const environment: EnvironmentModel = getEnv(window.location.host);
