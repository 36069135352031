import React, { useEffect } from 'react';
import './dashboard.page.scss';
import UsuarioModel from '../../models/usuario.model';
import { getAuthUser } from '../../services/auth';
import { LoadingService } from '../../services/loading.service';
import { SnackService } from '../../services/snack.service';
import { GetDashboardService } from '../../services/api-pages.service';
import DashboardModel, { RankingLojasCliModel, RankingLojasPneusModel, RankingLojasVendasModel, RankingPneusModel } from '../../models/dashboard.model';

function DashboardPage() {

  const [usuarioLogado, setUsuarioLogado] = React.useState<UsuarioModel>(getAuthUser());
  const [dataDashboard, setDataDashboard] = React.useState<DashboardModel>();
  const [rankingPneus, setRankingPneus] = React.useState<RankingPneusModel[]>();
  const [rankingLojasCli, setRankingLojasCli] = React.useState<RankingLojasCliModel[]>();
  const [rankingLojasVendas, setRankingLojasVendas] = React.useState<RankingLojasVendasModel[]>();
  const [rankingLojasPneus, setRankingLojasPneus] = React.useState<RankingLojasPneusModel[]>();


  useEffect(() => {
    GetDashboard();
  }, []);

  const GetDashboard = () => {

    LoadingService.sendLoadingAtivo(true);

    GetDashboardService({ id: 777 }).then((res) => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data?.error) {
        SnackService.sendMSG(res.data?.error);
      } else {
        setDataDashboard(res?.data);
        RankingPneus(res.data.ranking_pneus);
        RankingLojasCli(res.data.ranking_lojas_cli);
        RankingLojasVendas(res.data.ranking_lojas_vendas);
        RankingLojasPneus(res.data.ranking_lojas_pneus);
      }
    }).catch(err => {
      LoadingService.sendLoadingAtivo(false);
      SnackService.sendMSG('Erro com a conexão.');
      console.log(err);
    });
  }

  const BRLMask = (valor: number | undefined) => {
    if (valor != undefined) {
      return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }
  }

  const RankingPneus = (data: any) => {
    var result: any = [];
    data.reduce(function (res: any, value: any) {
      if (!res[value.produto]) {
        res[value.produto] = { produto: value.produto, quantidade: 0 };
        result.push(res[value.produto])
      }
      res[value.produto].quantidade += value.quantidade;
      return res;
    }, {});

    result = result.sort(({ quantidade: a }, { quantidade: b }) => b - a);
    setRankingPneus(result);
  }

  const RankingLojasCli = (data: any) => {
    var result: any = [];
    data.reduce(function (res: any, value: any) {
      if (!res[value.nome_loja]) {
        res[value.nome_loja] = { nome_loja: value.nome_loja, quantidade: 0 };
        result.push(res[value.nome_loja])
      }
      res[value.nome_loja].quantidade += value.quantidade;
      return res;
    }, {});

    result = result.sort(({ quantidade: a }, { quantidade: b }) => b - a);
    setRankingLojasCli(result);
  }

  const RankingLojasVendas = (data: any) => {
    var result: any = [];
    data.reduce(function (res: any, value: any) {
      if (!res[value.nome_loja]) {
        res[value.nome_loja] = { nome_loja: value.nome_loja, cnpj_loja: value.cnpj_loja, valor_da_venda: 0 };
        result.push(res[value.nome_loja])
      }
      res[value.nome_loja].valor_da_venda += value.valor_da_venda;
      return res;
    }, {});

    result = result.sort(({ valor_da_venda: a }, { valor_da_venda: b }) => b - a);
    setRankingLojasVendas(result);
  }

  const RankingLojasPneus = (data: any) => {
    var result: any = [];
    data.reduce(function (res: any, value: any) {
      if (!res[value.nome_loja]) {
        res[value.nome_loja] = { nome_loja: value.nome_loja, qtd_pneus: 0 };
        result.push(res[value.nome_loja])
      }
      res[value.nome_loja].qtd_pneus += value.qtd_pneus;
      return res;
    }, {});

    result = result.sort(({ qtd_pneus: a }, { qtd_pneus: b }) => b - a);
    setRankingLojasPneus(result);
  }

  return (
    <>
      {
        usuarioLogado?.user_role == 'administrator' ?
          <section className="dashboard">
            <div className="container">
              <h1>Dashboard</h1>
              <div className="cards">
                {/* <div className="card">
                  <h4>Lojas cadastradas</h4>
                  <h2>{dataDashboard?.lojas_cadastradas}</h2>
                </div> */}
                <div className="card">
                  <h4>Clientes PF</h4>
                  <h2>{dataDashboard?.qtd_clientes_cpf}</h2>
                </div>
                <div className="card">
                  <h4>Clientes PJ</h4>
                  <h2>{dataDashboard?.qtd_clientes_cnpj}</h2>
                </div>
                <div className="card">
                  <h4>Vendas realizadas</h4>
                  <h2>{dataDashboard?.qtd_vendas}</h2>
                </div>
                <div className="card">
                  <h4>Vendas realizadas (R$)</h4>
                  <h2>{BRLMask(dataDashboard?.valor_vendas)}</h2>
                </div>
                <div className="card">
                  <h4>Pneus vendidos</h4>
                  <h2>{dataDashboard?.pneus_vendidos}</h2>
                </div>
                <div className="card">
                  <h4>Descontos concedidos (R$)</h4>
                  <h2>{BRLMask(dataDashboard?.valor_desconto)}</h2>
                </div>
                <div className="card">
                  <h4>Pneus disponibilizados</h4>
                  <h2>{dataDashboard?.pneus_disponiveis}</h2>
                </div>
                <div className="card">
                  <h4>Pneus provisionados</h4>
                  <h2>{dataDashboard?.pneus_provisionados}</h2>
                </div>
                <div className="card">
                  <h4>Descontos provisionados</h4>
                  <h2>{BRLMask(dataDashboard?.descontos_provisionados)}</h2>
                </div>
                <div className="card full">
                  <h5>Ranking pneus</h5>
                  {
                    rankingPneus &&
                    <div className="itens">
                      {
                        rankingPneus.map((itemRanking, iRanking) => (
                          <div className="item" key={iRanking}>
                            <h3>{iRanking + 1}º lugar</h3>
                            <strong>{itemRanking.produto}</strong>
                            <p>Quantidade: {itemRanking.quantidade}</p>
                          </div>
                        ))
                      }
                    </div>
                  }
                </div>
                <div className="card full">
                  <h5>Ranking lojas (cadastro clientes)</h5>
                  {
                    rankingLojasCli &&
                    <div className="itens">
                      {
                        rankingLojasCli.map((itemRanking, iRanking) => (
                          <div className="item" key={iRanking}>
                            <h3>{iRanking + 1}º lugar</h3>
                            <strong>{itemRanking.nome_loja}</strong>
                            <p>Quantidade: {itemRanking.quantidade}</p>
                          </div>
                        ))
                      }
                    </div>
                  }
                </div>
                <div className="card full">
                  <h5>Ranking lojas vendas (R$)</h5>
                  {
                    rankingLojasVendas &&
                    <div className="itens">
                      {
                        rankingLojasVendas.map((itemRanking, iRanking) => (
                          <div className="item" key={iRanking}>
                            <h3>{iRanking + 1}º lugar</h3>
                            <strong>{itemRanking.nome_loja}</strong>
                            {/* <p>(CNPJ: {itemRanking.cnpj_loja})</p> */}
                            <p>Valor das vendas: {BRLMask(itemRanking.valor_da_venda)}</p>
                          </div>
                        ))
                      }
                    </div>
                  }
                </div>
                <div className="card full">
                  <h5>Ranking lojas (Pneus)</h5>
                  {
                    rankingLojasPneus &&
                    <div className="itens">
                      {
                        rankingLojasPneus.map((itemRanking, iRanking) => (
                          <div className="item" key={iRanking}>
                            <h3>{iRanking + 1}º lugar</h3>
                            <strong>{itemRanking.nome_loja}</strong>
                            <p>Pneus: {itemRanking.qtd_pneus}</p>
                          </div>
                        ))
                      }
                    </div>
                  }
                </div>
              </div>
            </div>
          </section>
          :
          <section className="dash-erro">
            <div className="container">
              <div className="flex flex-ch">
                <p>Página exclusiva para admin</p>
              </div>
            </div>
          </section>
      }
    </>
  );
}

export default DashboardPage;
