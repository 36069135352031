import axios from "axios";
import { environment } from "../environments/environment";

export const api = axios.create({
    baseURL: environment.serverUrl
});

export async function PageLoginService(dto: any) {
    let url = '/paginas/login';
    return await api.post(url, dto, {});
}

export async function PageHomeService(dto: any) {
    let url = '/paginas/home';
    return await api.post(url, dto, {});
}

export async function PageRegulamentoService(dto: any) {
    let url = '/paginas/regulamento';
    return await api.post(url, dto, {});
}

export async function PageDownloadsService(dto: any) {
    let url = '/paginas/downloads';
    return await api.post(url, dto, {});
}

export async function GetDashboardService(dto: { id: number }) {
    let url = '/apis/dashboard';
    return await api.post(url, dto, {});
}