import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import './select-auditoria.component.scss';
import { LoadingService } from '../../services/loading.service';
import { SnackService } from '../../services/snack.service';
import { AtualizaVendaService } from '../../services/api-usuario.service';

export type SelectAuditoriaComponentProps = {
  valSelect?: string;
  post_id?: number;
}

function SelectAuditoriaComponent(props: SelectAuditoriaComponentProps) {
  const [auditoria, setAuditoria] = React.useState(props.valSelect ? props.valSelect : "PENDENTE");

  const handleChange = (event: SelectChangeEvent) => {
    setAuditoria(event.target.value as string);
    AtualizaVenda(event.target.value);
  };

  const AtualizaVenda = (auditoria_input: string) => {
    LoadingService.sendLoadingAtivo(true);

    var dto: any = {
      post_id: props.post_id,
      auditoria: auditoria_input
    };

    AtualizaVendaService(dto).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        console.log('res.data', res.data);
        SnackService.sendMSG('Status atualizado', undefined, undefined, 'azul');
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão ao carregar página.');
      console.log(err);
    });
  }

  return (
    <div className={'box-select ' + auditoria?.toLowerCase()}>
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Auditoria</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={auditoria}
            label="Auditoria"
            onChange={handleChange}
          >
            <MenuItem value="PENDENTE">PENDENTE</MenuItem>
            <MenuItem value="APROVADO">APROVADO</MenuItem>
            <MenuItem value="REPROVADO">REPROVADO</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </div>
  );
}

export default SelectAuditoriaComponent;
