import React, { useEffect } from 'react';
import './regulamento.page.scss';
import { LoadingService } from '../../services/loading.service';
import { SnackService } from '../../services/snack.service';
import { PageRegulamentoService } from '../../services/api-pages.service';
import RegulamentoPageModel from '../../models/regulamento-page.model';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

function RegulamentoPage() {

  const [page, setPage] = React.useState<RegulamentoPageModel>();
  const [banner, setBanner] = React.useState<string>('');
  const [bannerMobile, setBannerMobile] = React.useState<string>('');
  let history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      GetPageRegulamentoService();
    }, 500);
  }, []);

  const GetPageRegulamentoService = () => {
    LoadingService.sendLoadingAtivo(true);

    var dto = {
      user_email: 'contato@prometeoncomvoce.com.br',
    };

    PageRegulamentoService(dto).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setPage(res.data);
        setBanner(res.data.banner);
        setBannerMobile(res.data.banner_mobile);
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão ao carregar página.');
      console.log(err);
    });
  }

  const GoPage = () => {
    history?.goBack();
  }

  return (
    <>
      {
        page &&
        <section className="regulamento">
          {
            banner &&
            <img className='banner desktop' src={banner} alt="Banner" />
          }

          {
            bannerMobile &&
            <img className='banner mobile' src={bannerMobile} alt="Banner" />
          }
          <div className="container">
            <div className="flex flex-sp">
              <h1>{page?.titulo}</h1>
              <Button onClick={GoPage}>Voltar</Button>
            </div>
            <div className="txt" dangerouslySetInnerHTML={{ __html: page?.conteudo }}></div>
          </div>
        </section>
      }
    </>
  );
}

export default RegulamentoPage;
