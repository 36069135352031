import axios from "axios";
import { environment } from "../environments/environment";
import any from "../models/usuario.model";
import ClientesModel from "../models/clientes.model";
import BuscaClienteModel from "../models/busca-cliente.model";
import VendaModel from "../models/venda.model";

export const api = axios.create({
    baseURL: environment.serverUrl
});

export async function LoginService(dto: any) {
    let url = '/apis/login-usuario';
    return await api.post(url, dto, {});
}

export async function CadastrarService(dto: any) {
    let url = '/apis/cadastrar-usuario';
    return await api.post(url, dto, {});
}

export async function AtualizarUsuarioService(dto: any) {
    let url = '/apis/atualizar-usuario';
    return await api.post(url, dto, {});
}

export async function EsqueciMinhaSenhaService(dto: any) {
    let url = '/apis/esqueci-minha-senha';
    return await api.post(url, dto, {});
}

export async function CadastrarClienteService(dto: ClientesModel) {
    let url = '/apis/cadastrar-cliente';
    return await api.post(url, dto, {});
}

export async function CadastrarVendaService(dto: VendaModel) {
    let url = '/apis/atualizar-venda';
    return await api.post(url, dto, {});
}

export async function UploadFileService(dto: any) {
    let url = '/apis/upload-file';
    return await api.post(url, dto, {});
}

export async function BuscaClienteService(dto: BuscaClienteModel) {
    let url = '/apis/buscar-cliente';
    return await api.post(url, dto, {});
}

export async function ListaProdutosService(dto: { email: string }) {
    let url = '/apis/lista-produtos';
    return await api.post(url, dto, {});
}

export async function ListaVendasService(dto: { user_id: number }) {
    let url = '/apis/lista-vendas';
    return await api.post(url, dto, {});
}

export async function AtualizaVendaService(dto: { post_id: number, auditoria: string | undefined }) {
    let url = '/apis/atualiza-venda';
    return await api.post(url, dto, {});
}