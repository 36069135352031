import { Subject } from 'rxjs';

const subject = new Subject();

export const SnackService = {
    sendMSG: (
        msg: string,
        vertical?: string,
        timeSnak?: number,
        cor?: string
    ) => subject.next({
        msg: msg,
        vertical: vertical,
        timeSnak: timeSnak,
        cor: cor
    }),
    getMSG: () => subject.asObservable()
};