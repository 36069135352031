import React, { useEffect } from 'react';
import './acesso.page.scss';
import LoginComponent from '../../components/login/login.component';
import { Button } from '@mui/material';
import CadastroComponent from '../../components/cadastro/cadastro.component';
import { EsqueciMinhaSenhaComponent } from '../../components/esqueci-minha-senha/esqueci-minha-senha.component';
import logoPrometeon from '../../assets/logo-prometeon.svg';
import { PageLoginService } from '../../services/api-pages.service';
import { LoadingService } from '../../services/loading.service';
import { SnackService } from '../../services/snack.service';
import { useHistory } from 'react-router-dom';

export default function AcessoPage() {

  let history = useHistory();
  const [showBox, setShowBox] = React.useState<string>('login');
  const [banner, setBanner] = React.useState<string>('');
  const [bannerMobile, setBannerMobile] = React.useState<string>('');

  useEffect(() => {
    setTimeout(() => {
      GetBanner();
    }, 500);
  }, []);

  const GetBanner = () => {
    LoadingService.sendLoadingAtivo(true);

    var dto = {
      user_email: 'contato@prometeoncomvoce.com.br',
    };

    PageLoginService(dto).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setBanner(res.data.banner);
        setBannerMobile(res.data.banner_mobile);
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão ao carregar página.');
      console.log(err);
    });
  }

  const GoPage = (page: string) => {
    history?.push({
      pathname: `/${page}`
    })
  }

  return (
    <>
      <section className="acesso">
        {
          banner &&
          <img className='banner desktop' src={banner} alt="Banner" />
        }

        {
          bannerMobile &&
          <img className='banner mobile' src={bannerMobile} alt="Banner" />
        }

        <div className="container">
          <div className="faixa-topo flex flex-ri">
            <Button onClick={() => GoPage('regulamento')}>Regulamento</Button>
          </div>

          <div className="box-login">
            <img src={logoPrometeon} alt="Logo Prometeon" />
            {
              showBox === 'login' &&
              <div className="box">
                <LoginComponent />
                <div className="flex flex-ch flex-cv">
                  <small>Primeiro acesso?</small>
                  <Button onClick={() => { setShowBox('cadastro') }}> Cadastre-se</Button>
                  <Button onClick={() => { setShowBox('esqueci-minha-senha') }}>Esqueci minha senha</Button>
                </div>
              </div>
            }
            {
              showBox === 'cadastro' &&
              <div className="box">
                <CadastroComponent />
                <Button onClick={() => { setShowBox('login') }}>Efetuar login</Button>
              </div>
            }
            {
              showBox === 'esqueci-minha-senha' &&
              <div className="box">
                <EsqueciMinhaSenhaComponent />
                <div className="flex flex-ch flex-cv">
                  <small>Primeiro acesso?</small>
                  <Button onClick={() => { setShowBox('cadastro') }}>Cadastre-se</Button>
                  <Button onClick={() => { setShowBox('login') }}>Efetuar login</Button>
                </div>
              </div>
            }
          </div>
        </div>
      </section>
    </>
  );
}