import { Subject } from 'rxjs';
import UsuarioModel from '../models/usuario.model';

const subject = new Subject();

export const UsuarioEfetuouLoginService = {
    sendMSG: (msg: UsuarioModel) => subject.next(msg),
    getMSG: () => subject.asObservable()
};

export const UsuarioEfetuouLogOutService = {
    sendMSG: (msg: boolean) => subject.next(msg),
    getMSG: () => subject.asObservable()
};

export const UsuarioLogadoService = {
    getUsuario: () => {
        let usLogado = localStorage.getItem("user");
        if (usLogado) {
            return JSON.parse(usLogado) as UsuarioModel;
        }
    }
};