import React, { useEffect } from 'react';
import { PageHomeService } from '../../services/api-pages.service';
import { LoadingService } from '../../services/loading.service';
import { SnackService } from '../../services/snack.service';
import './home.page.scss';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

export default function HomePage() {

  const [banner, setBanner] = React.useState<string>('');
  const [bannerMobile, setBannerMobile] = React.useState<string>('');
  let history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      GetBanner();
    }, 500);
  }, []);

  const GetBanner = () => {
    LoadingService.sendLoadingAtivo(true);

    var dto = {
      user_email: 'contato@prometeoncomvoce.com.br',
    };

    PageHomeService(dto).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setBanner(res.data.banner);
        setBannerMobile(res.data.banner_mobile);
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão ao carregar página.');
      console.log(err);
    });
  }

  const GoPage = (page: string) => {
    history?.push({
      pathname: `/${page}`
    })
  }

  return (
    <>
      <section className="home">
        {
          banner &&
          <img className='banner desktop' src={banner} alt="Banner" />
        }

        {
          bannerMobile &&
          <img className='banner mobile' src={bannerMobile} alt="Banner" />
        }
        <div className="container">
          <div className="bts">
            {/* <Button onClick={() => GoPage('cadastro-cliente')}>
              <span>passo 1</span>
              <h4>Cadastrar cliente</h4>
            </Button> */}
            <Button onClick={() => GoPage('cadastro-venda')}>
              <span>passo 2</span>
              <h4>Cadastrar venda</h4>
            </Button>
          </div>
        </div>
      </section>
    </>
  );
}