import React, { useEffect } from 'react';
import './downloads.page.scss';
import { LoadingService } from '../../services/loading.service';
import { SnackService } from '../../services/snack.service';
import { PageDownloadsService, PageRegulamentoService } from '../../services/api-pages.service';
import RegulamentoPageModel from '../../models/regulamento-page.model';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import DownloadsPageModel from '../../models/downloads-page.model';
import Accordion, {
  AccordionSlots,
  accordionClasses,
} from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails, {
  accordionDetailsClasses,
} from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from '@mui/material/Fade';
import FolderIcon from '@mui/icons-material/Folder';

function DownloadsPage() {

  const [page, setPage] = React.useState<DownloadsPageModel>();
  let history = useHistory();

  const [isOpen, setOpen] = React.useState<number>(0);

  const handleOpen = (value: number) => setOpen(isOpen === value ? 0 : value);

  useEffect(() => {
    setTimeout(() => {
      GetPageDownloadsService();
    }, 500);
  }, []);

  const GetPageDownloadsService = () => {
    LoadingService.sendLoadingAtivo(true);

    var dto = {
      user_email: 'contato@prometeoncomvoce.com.br',
    };

    PageDownloadsService(dto).then(res => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data.error) {
        SnackService.sendMSG(res.data.error);
      } else {
        setPage(res.data);
      }
    }).catch(err => {
      SnackService.sendMSG('Erro com a conexão ao carregar página.');
      console.log(err);
    });
  }

  const GoPage = () => {
    history?.goBack();
  }

  return (
    <>
      {
        page &&
        <section className="downloads">
          <div className="container">
            <h1>{page.titulo}</h1>
            <div className="conteudo" dangerouslySetInnerHTML={{ __html: page.conteudo }}></div>
            {
              page.downloads &&
              <>
                {
                  page.downloads.map((itemDownload, iDownload) => (
                    <>
                      <Accordion
                        key={iDownload}
                        onChange={() => handleOpen(iDownload + 1)}
                        expanded={isOpen === (iDownload + 1)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={"panel" + iDownload + "-content"}
                          id={"panel" + iDownload + "-header"}
                        >
                          <Typography>
                            <div className="flex flex-sp flex-cv">
                              <FolderIcon />
                              {itemDownload.titulo}
                              <img src={itemDownload.thumb} alt="itemDownload.titulo" />
                            </div>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            {
                              itemDownload.arquivos &&
                              itemDownload.arquivos.map((itemArquivo, iArquivo) => (
                                <p>
                                  <Button key={iArquivo} href={itemArquivo.arquivo.url} target='_blank'>
                                    {itemArquivo.arquivo.filename}
                                  </Button>
                                </p>
                              ))
                            }
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </>
                  ))
                }
              </>
            }
          </div>
        </section>
      }
    </>
  );
}

export default DownloadsPage;
