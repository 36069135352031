import React, { useEffect, useLayoutEffect, useRef } from 'react';
import './cadastro-venda.page.scss';
import BuscaComponent from '../../components/busca-cliente/busca-cliente.component';
import ClientesModel from '../../models/clientes.model';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { LoadingService } from '../../services/loading.service';
import { useInput } from '../../hooks/useInput.hook';
import { Autocomplete, Button, TextField } from '@mui/material';
import CamposProdutosComponent from '../../components/campos-produtos/campos-produtos.component';
import ProdutoFormModel from '../../models/produto-form.model';
import { CadastrarVendaService, ListaProdutosService, UploadFileService } from '../../services/api-usuario.service';
import { SnackService } from '../../services/snack.service';
import ProdutoModel from '../../models/produto-form.model';
import VendaModel from '../../models/venda.model';
import UsuarioModel from '../../models/usuario.model';
import { BuscaClienteOBSService } from '../../services/busca-cliente.service';

export default function CadastroVendaPage(props: any) {

  var dto: VendaModel;
  const [cliente, setCliente] = React.useState<ClientesModel>();
  const [vendaPainvel, setVendaPainvel] = React.useState<VendaModel | undefined>(props.history.location.state?.vendaPainvel);
  const [fileSelected, setFileSelected] = React.useState<File | undefined>();
  const [refFile, setRefFile] = React.useState<any>();
  const [totalDesconto, setTotalDesconto] = React.useState<string | undefined>();
  const [postID, setPostID] = React.useState<number>();
  const [totalVendaTabela, setTotalVendaTabela] = React.useState<string>();
  const [totalDescontoTabela, setTotalDescontoTabela] = React.useState<string>();
  //const { value: valorVendaInputText, bind: bindValorVendaInputText, setValue: setValorVendaInputText } = useInput(0);

  const [valorVendaInputText, setValorVendaInputText] = React.useState<string>();

  const [listaFieldsProdutos, setListaFieldsProdutos] = React.useState<ProdutoFormModel[] | undefined>();

  const [listaProdutos, setListaProdutos] = React.useState<string[]>([]);
  const [nfAnexada, setNfAnexada] = React.useState<string>();
  const [usuarioLogado, setUsuarioLogado] = React.useState<UsuarioModel>();
  const [isAtualizarVenda, setIsAtualizarVenda] = React.useState<boolean>(false);
  const [postIdVenda, setPostIdVenda] = React.useState<number>();
  const [totalQTD, setTotalQTD] = React.useState<number>();

  useLayoutEffect(() => {
    //calcTotalDescontos();
    if (vendaPainvel) {
      setTimeout(() => {
        BuscaClienteOBSService.sendBusca(vendaPainvel.cpf_cnpj);
      }, 500);
    }

    let usLogado = localStorage.getItem("user");
    if (usLogado) {
      setUsuarioLogado(JSON.parse(usLogado));
    }
    ListarProdutos();
  }, []);

  useLayoutEffect(() => {
    setListaFieldsProdutos(listaFieldsProdutos);
  }, [listaFieldsProdutos, totalDesconto, cliente]);

  const ListarProdutos = () => {

    LoadingService.sendLoadingAtivo(true);

    ListaProdutosService({ email: 'contato@prometeon.com.br' }).then((res) => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data?.error) {
        SnackService.sendMSG(res.data?.error);
      } else {
        setListaProdutos(res?.data);
      }
    }).catch(err => {
      LoadingService.sendLoadingAtivo(false);
      SnackService.sendMSG('Erro com a conexão.');
      console.log(err);
    });
  }

  const CpfCnpjMask = (v: string) => {
    v = v.replace(/\D/g, "");
    if (v.length <= 13) {
      //v = v.substring(0, 11);
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    } else {
      v = v.replace(/\D+/g, '');
      v = v.replaceAll('.', '');
      v = v.replaceAll('-', ''); // não deixa ser digitado nenhuma letra
      v = v.replace(/(\d{2})(\d)/, '$1.$2'); // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
      v = v.replace(/(\d{3})(\d)/, '$1.$2');
      v = v.replace(/(\d{3})(\d)/, '$1/$2'); // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
      v = v.replace(/(\d{4})(\d)/, '$1-$2');
      v = v.replace(/(-\d{2})\d+?$/, '$1'); // captura os dois últimos 2 números, com um - antes dos dois números
    }

    return v;
  }

  const BRLMask = (valor: any) => {
    if (valor) {
      let valorAlterado = valor;
      valorAlterado = valorAlterado.replace("R$ ", "");
      valorAlterado = valorAlterado.replace(/\D/g, ""); // Remove todos os não dígitos
      valorAlterado = valorAlterado.replace(/(\d+)(\d{2})$/, "$1,$2"); // Adiciona a parte de centavos
      valorAlterado = valorAlterado.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."); // Adiciona pontos a cada três dígitos
      valorAlterado = "R$ " + valorAlterado;
      return valorAlterado;
    }
  }

  const handleFileChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files;

    if (!fileList) {
      LoadingService.sendLoadingAtivo(false);
      return;
    }

    setFileSelected(fileList[0]);
    LoadingService.sendLoadingAtivo(false);
    //uploadFile();
  };

  const getFile = function () {
    if (fileSelected) {
      const formData = new FormData();
      formData.append("file", fileSelected, fileSelected.name + '-777-' + usuarioLogado?.ID + '-777-' + postIdVenda);
      formData.append('action', 'md_support_save');

      return formData;
    }
  };

  const calcTotalDescontos = (listaDelete?: ProdutoFormModel[], listaEdit?: ProdutoFormModel[], clienteBusca?: ClientesModel) => {
    if (listaFieldsProdutos || listaEdit || listaDelete || clienteBusca) {
      let somaQTDs: number = 0;
      setTotalDesconto('0');

      if (cliente?.vendas) {
        cliente.vendas.map(itemVenda => {
          itemVenda.produtos_vendidos?.map(itemProduto => {
            if (postIdVenda != itemVenda.post_id) {
              somaQTDs += parseInt(itemProduto.quantidade + '');
            }
          })
        });
      }

      if (!cliente?.vendas && clienteBusca) {
        clienteBusca.vendas?.map(itemVenda => {
          itemVenda.produtos_vendidos?.map(itemProduto => {
            if (postIdVenda != itemVenda.post_id) {
              somaQTDs += parseInt(itemProduto.quantidade + '');
            }
          })
        });
      }

      if (listaDelete) {
        listaDelete.map(item => {
          somaQTDs += parseInt(item.quantidade + '');
        });
      } else if (listaEdit) {
        listaEdit.map(item => {
          somaQTDs += parseInt(item.quantidade + '');
        });
      } else {
        if (listaFieldsProdutos) {
          listaFieldsProdutos.map(item => {
            somaQTDs += parseInt(item.quantidade + '');
          });
        }
      }

      setTotalQTD(somaQTDs - 1);

      setTimeout(() => {
        if (somaQTDs == 2) {
          verificaVendasParaCalc('450', listaEdit, clienteBusca);
        } else if (somaQTDs == 3) {
          verificaVendasParaCalc('675', listaEdit, clienteBusca);
        } else if (somaQTDs == 4) {
          verificaVendasParaCalc('900', listaEdit, clienteBusca);
        } else if (somaQTDs == 5) {
          verificaVendasParaCalc('1125', listaEdit, clienteBusca);
        } else if (somaQTDs == 6) {
          verificaVendasParaCalc('1350', listaEdit, clienteBusca);
        } else if (somaQTDs == 7) {
          verificaVendasParaCalc('1575', listaEdit, clienteBusca);
        } else if (somaQTDs == 8) {
          verificaVendasParaCalc('1800', listaEdit, clienteBusca);
        } else if (somaQTDs == 9) {
          verificaVendasParaCalc('2025', listaEdit, clienteBusca);
        } else if (somaQTDs >= 10) {
          verificaVendasParaCalc('2250', listaEdit, clienteBusca);
        } else {
          verificaVendasParaCalc('0', listaEdit, clienteBusca);
        }
      }, 100);
    }
  }

  const verificaVendasParaCalc = (val: string, listaEdit?: ProdutoFormModel[], clienteBusca?: ClientesModel) => {
    let valVendas = 0;
    if (cliente?.vendas) {
      cliente.vendas.map(itemVenda => {
        if (postIdVenda != itemVenda.post_id) {
          let formatN = itemVenda.total_desconto?.replace('R$ ', '').replaceAll('.', '').replaceAll(',', '.') + '';
          valVendas += parseInt(formatN);
        }
      });

      if (valVendas >= parseInt(val)) {
        //valVendas = valVendas - parseInt(val);
        valVendas = 0;
      } else {
        valVendas = parseInt(val) - valVendas;
      }
    }

    if (!cliente?.vendas && clienteBusca) {
      clienteBusca.vendas?.map(itemVenda => {
        if (postIdVenda != itemVenda.post_id) {
          let formatN = itemVenda.total_desconto?.replace('R$ ', '').replaceAll('.', '').replaceAll(',', '.') + '';
          valVendas += parseInt(formatN);
        }
      });

      if (valVendas >= parseInt(val)) {
        //valVendas = valVendas - parseInt(val);
        valVendas = 0;
      } else {
        valVendas = parseInt(val) - valVendas;
      }
    }

    setTotalDesconto(BRLMask(valVendas + ',00'));
  }

  const CamposProdutosComponentChange = (produto: ProdutoFormModel, index: number) => {
    if (listaFieldsProdutos) {
      let newItens: ProdutoFormModel[] = listaFieldsProdutos;
      newItens[index] = produto;
      setListaFieldsProdutos(newItens);
      calcTotalDescontos();
    }
  }

  const adicionarProduto = () => {
    let newArr: ProdutoFormModel[] | undefined = listaFieldsProdutos ? listaFieldsProdutos : [];
    newArr?.push({ produto: '', quantidade: 1 });
    setListaFieldsProdutos(newArr);
    calcTotalDescontos();
  }

  const deletarProduto = (indexProd: number) => {
    if (listaFieldsProdutos) {
      let arrTemp: ProdutoFormModel[] = listaFieldsProdutos;
      arrTemp = arrTemp?.filter((_, i) => i !== indexProd);
      setListaFieldsProdutos([]);
      calcTotalDescontos();

      setTimeout(() => {
        setListaFieldsProdutos([...arrTemp]);
        calcTotalDescontos(arrTemp);
      }, 100);
    }
  }

  const UploadFile = () => {

    let validaCampoProdutos = true;

    if (listaFieldsProdutos?.length) {
      listaFieldsProdutos.map(item => {
        if (item.produto == '') {
          validaCampoProdutos = false;
        }

        let isProdutoDaLista: any = listaProdutos.find(x => x === item.produto);
        if (!isProdutoDaLista) {
          validaCampoProdutos = false;
        }
      })
    } else {
      SnackService.sendMSG('O campo "Produto" é obrigatório.');
      return;
    }

    if (!validaCampoProdutos) {
      SnackService.sendMSG('O campo "Produto" é obrigatório e deve ser um nome válido da lista.');
      return;
    } else if (!valorVendaInputText) {
      SnackService.sendMSG('O campo "Valor NF" é obrigatório');
      return;
    } else if (!fileSelected) {
      if (isAtualizarVenda && postIdVenda) {
        atualizarVenda(postIdVenda);
      } else {
        SnackService.sendMSG('O campo "NF" é obrigatório');
      }
      return;
    }

    LoadingService.sendLoadingAtivo(true);

    console.log('olas', getFile());

    UploadFileService(getFile()).then((res) => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data?.error) {
        SnackService.sendMSG(res.data?.error);
      } else {
        if (res?.data?.post_id) {
          atualizarVenda(res.data.post_id);
          setNfAnexada(res.data.nf);
        } else if (res?.data?.upload_ok) {
          if (postIdVenda) {
            atualizarVenda(postIdVenda);
            setNfAnexada(res.data.nf);
          }
        } else {
          SnackService.sendMSG('Algo deu errado.');
        }
      }
    }).catch(err => {
      LoadingService.sendLoadingAtivo(false);
      SnackService.sendMSG('Erro com a conexão.');
      console.log(err);
    });
  }

  const atualizarVenda = (post_id: number) => {
    dto = {
      post_id: post_id,
      user_id: usuarioLogado?.ID,
      cpf_cnpj: cliente?.cpf_cnpj,
      valor_da_venda: valorVendaInputText,
      nf: '',
      total_desconto: totalDesconto,
      produtos_vendidos: listaFieldsProdutos
    };

    LoadingService.sendLoadingAtivo(true);

    CadastrarVendaService(dto).then((res) => {
      LoadingService.sendLoadingAtivo(false);
      if (res.data?.error) {
        SnackService.sendMSG(res.data?.error);
      } else {
        if (res?.data?.venda_cadastrada) {
          if (postIdVenda) {
            SnackService.sendMSG(res?.data?.venda_editada, 'center', undefined, 'azul');
          } else {
            SnackService.sendMSG(res?.data?.venda_cadastrada, 'center', undefined, 'azul');
          }
          setIsAtualizarVenda(false);
          setPostIdVenda(undefined);
          setListaFieldsProdutos([]);
          setNfAnexada(undefined);
          setFileSelected(undefined);
          setValorVendaInputText('');
          setTotalDesconto(undefined);
          setVendaPainvel(undefined);
          BuscaClienteOBSService.sendBusca(cliente?.cpf_cnpj);
        } else {
          SnackService.sendMSG('Algo deu errado.');
        }
      }
    }).catch(err => {
      LoadingService.sendLoadingAtivo(false);
      SnackService.sendMSG('Erro com a conexão.');
      console.log(err);
    });
  }

  const atualizaCliente = (clienteBusca: ClientesModel) => {

    if (!clienteBusca) {
      return
    }

    setCliente(clienteBusca);
    // setIsAtualizarVenda(true);
    // setPostIdVenda(vendaPainvel.post_id);

    let somaVendas = 0;
    let somaDescontos = 0;
    clienteBusca.vendas?.map(item => {
      somaVendas += parseInt(item.valor_da_venda?.replaceAll('R$ ', '').replaceAll('.', '').replaceAll(',', '.') + '');
      somaDescontos += parseInt(item.total_desconto?.replaceAll('R$ ', '').replaceAll('.', '').replaceAll(',', '.') + '');
    });

    setTotalDescontoTabela(BRLMask(somaDescontos + ',00'));
    setTotalVendaTabela(BRLMask(somaVendas + ',00'));
    // setValorVendaInputText(cliente.valor_da_venda);
    // setListaFieldsProdutos(cliente.produtos_vendidos);
    // setTotalDesconto(cliente.total_desconto);
    // setNfAnexada(cliente.nf);

    if (vendaPainvel) {
      let newArr: ProdutoFormModel[] | undefined = vendaPainvel.produtos_vendidos;
      setListaFieldsProdutos([]);
      //setTotalDesconto('99999999');
      editarVenda(vendaPainvel, clienteBusca);
      setTimeout(() => {
        calcTotalDescontos(undefined, newArr, clienteBusca);
      }, 200);
    }
  }

  const changeInputValorVenda = (newInputValue: any) => {
    setValorVendaInputText(BRLMask(newInputValue.target.value));
  }

  const editarVenda = (venda: VendaModel, clienteBusca?: ClientesModel) => {
    setFileSelected(undefined);
    setIsAtualizarVenda(true);
    console.log('--venda.nf', venda.nf);
    console.log('--venda', venda);
    setNfAnexada(venda.nf);
    //setValorVendaInputText(venda.valor_da_venda ? venda.valor_da_venda : '');
    setPostIdVenda(venda.post_id);
    setValorVendaInputText(venda.valor_da_venda);

    let newArr: ProdutoFormModel[] | undefined = venda.produtos_vendidos;
    setListaFieldsProdutos([]);
    setTimeout(() => {
      if (newArr) {
        setListaFieldsProdutos([...newArr]);
        setTimeout(() => {
          calcTotalDescontos(undefined, newArr, clienteBusca);
        }, 100);
      }
    }, 100);
  }

  const RetornaSomaQTD = (produtos?: ProdutoModel[]) => {
    let soma = 0;
    produtos?.map(item => {
      soma += parseInt(item.quantidade + '');
    });

    return soma;
  }

  const CalcTotalVendas = (vendas: VendaModel[]) => {
    let _totVendas = 0;
    let _totQTD = 0;
    let _totDesconto = 0;

    vendas.map(itemVenda => {
      _totVendas += parseInt(itemVenda.valor_da_venda?.replace("R$ ", "").replace(/\D/g, "") + '');
      itemVenda.produtos_vendidos?.map(itemProduto => {
        _totQTD += parseInt(itemProduto.quantidade + '');
      });
      _totDesconto += parseInt(itemVenda.total_desconto?.replace("R$ ", "").replace(/\D/g, "") + '');
    });

    return _totQTD;
  }

  return (
    <>
      <section className="cadastro-venda">
        <div className="container">
          <div className="form">
            <h4>Passo 2:</h4>
            <h1>Cadastrar venda</h1>
            <BuscaComponent sendCliente={(cliente: ClientesModel) => { atualizaCliente(cliente) }} />
            {
              cliente &&
              <>
                <div className="box-info">
                  <h2>Dados do cliente</h2>
                  <div className="table-responsive">
                    <table className='cliente'>
                      <tbody>
                        <tr>
                          <td>Nome:</td>
                          <td>{cliente.nome}</td>
                        </tr>
                        <tr>
                          <td>CPF/CNPJ:</td>
                          <td>{CpfCnpjMask(cliente.cpf_cnpj)}</td>
                        </tr>
                        <tr>
                          <td>E-mail:</td>
                          <td>{cliente.email}</td>
                        </tr>
                        <tr>
                          <td>Tel/Cel:</td>
                          <td>{cliente.tel_cel}</td>
                        </tr>
                        <tr>
                          <td>Estado:</td>
                          <td>{cliente.estado}</td>
                        </tr>
                        <tr>
                          <td>Cidade:</td>
                          <td>{cliente.cidade}</td>
                        </tr>
                        <tr>
                          <td>Cupom:</td>
                          <td><span>{cliente.cupom}</span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="box-info">
                  <h2>Dados da venda</h2>
                  <div className="flex">
                    <div className="esq">
                      <div className="upload-nf">
                        <AttachFileIcon />
                        <h4>
                          <strong>Upload da Nota fiscal</strong>
                          <small>*Aceito arquivos em pdf ou imagem</small>
                          <span>{fileSelected?.name}</span>
                        </h4>
                        <input
                          accept="image/*,application/pdf"
                          id="photo"
                          name="photo"
                          type="file"
                          multiple={false}
                          onChange={handleFileChange}
                          ref={input => setRefFile(input)}
                        />
                      </div>
                      {
                        nfAnexada &&
                        <div className="box-link flex flex-ch">
                          <Button className='bt-link' href={nfAnexada} target='_blank'>NF anexada</Button>
                        </div>
                      }
                      <TextField onChange={(newInputValue) => changeInputValorVenda(newInputValue)} value={valorVendaInputText} label="Valor NF" />
                    </div>
                    <div className="dir">
                      <h4>Adicionar produtos vendidos:</h4>
                      {
                        listaFieldsProdutos ? listaFieldsProdutos.map((item, indexProduto) => (
                          <CamposProdutosComponent
                            key={indexProduto}
                            sendDeleteProduto={(index: number) => deletarProduto(index)}
                            produto={item}
                            index={indexProduto}
                            options={listaProdutos}
                            sendProduto={(produto: ProdutoFormModel, index: number) => {
                              CamposProdutosComponentChange(produto, index)
                            }} />
                        )) : <></>
                      }
                      <Button className='bt' onClick={adicionarProduto}>Adicionar produto</Button>
                      <div className="total-desconto">
                        <strong>Total desconto:</strong>
                        <span>{totalDesconto}</span>
                      </div>
                      {
                        !postIdVenda ?
                          <Button className='bt' onClick={UploadFile}>Cadastrar</Button>
                          :
                          <Button className='bt' onClick={UploadFile}>Salvar edição</Button>
                      }
                    </div>
                  </div>
                  <p className='msg-atencao'>
                    <strong>
                      Atenção: O desconto é aplicável a partir de 2 pneus e até o máximo de 10 pneus.
                    </strong>
                  </p>
                </div>
                {
                  cliente?.vendas &&
                  <div className="box-info">
                    <h2>Vendas realizadas</h2>
                    <div className="table-responsive">
                      <table className='vendas'>
                        <thead>
                          <tr>
                            {
                              usuarioLogado?.user_role == 'administrator' &&
                              <th>Vendedor</th>
                            }
                            <th>Data venda</th>
                            {
                              usuarioLogado?.user_role == 'administrator' &&
                              <th>Valor NF</th>
                            }
                            <th>Total desconto</th>
                            <th>QTD</th>
                            <th>NF</th>
                            <th>Editar</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            cliente?.vendas ? cliente.vendas.map((venda, indexVenda) => (
                              <tr key={indexVenda}>
                                {
                                  usuarioLogado?.user_role == 'administrator' &&
                                  <td>{venda.vendedor}</td>
                                }
                                <td>{venda.data}</td>
                                {
                                  usuarioLogado?.user_role == 'administrator' &&
                                  <td>{venda.valor_da_venda}</td>
                                }
                                <td>{venda.total_desconto}</td>
                                <td>{RetornaSomaQTD(venda.produtos_vendidos)}</td>
                                <td>
                                  {
                                    usuarioLogado?.ID == venda.user_id &&
                                    <Button href={venda.nf ? venda.nf : '#'} target='_blank'>Ver NF</Button>
                                  }
                                </td>
                                <td>
                                  {
                                    usuarioLogado?.ID == venda.user_id &&
                                    <Button className='bt' onClick={() => editarVenda(venda)}>Editar</Button>
                                  }
                                </td>
                              </tr>
                            )) : <span>Nenhum item encontrado.</span>
                          }
                          <tr>
                            {
                              usuarioLogado?.user_role == 'administrator' &&
                              <td></td>
                            }
                            <td></td>
                            {
                              usuarioLogado?.user_role == 'administrator' &&
                              <td>
                                <strong>{totalVendaTabela}</strong>
                              </td>
                            }
                            <td>
                              <strong>{totalDescontoTabela}</strong>
                            </td>
                            <td>
                              {CalcTotalVendas(cliente.vendas)}
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                }
              </>
            }
          </div>
        </div>
      </section>
    </>
  );
}